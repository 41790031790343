import { graphql } from "gatsby"
import React from "react"
import { Box } from "theme-ui"
import Text from "../components/base/text/text"
import ContentModules from "../components/content-modules"
import SEO from "../components/seo"


const Story = ({ data }) => {
  const { contentfulStory: story } = data
  const { metaDescription, metaTitle } = story

  return (
    <Box mx={["5%", "30%"]}>
      <SEO
        title={metaTitle || "Palmes Tennis Society"}
        description={
          metaDescription?.metaDescription || "Palmes Tennis Society"
        }
      />
      <Text sx={{ fontSize: [2, 4], display: "block" }}>{story?.title}</Text>
      <Text sx={{ fontSize: [1, 3], display: "block", marginBottom: 1 }}>
        {story?.description}
      </Text>

      <ContentModules
        allModules={story?.content}
        options={{ zoomOnImage: true }}
      />
    </Box>
  )
}

export default Story

export const query = graphql`
  query ($slug: String!) {
    contentfulStory(slug: { eq: $slug }) {
      ...StoryPage
    }
  }
`
